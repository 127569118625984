@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@300&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Cormorant Garamond', serif;
  /* font-size: 40px; */
  /* text-shadow: 2px 2px 2px #aaa; */
}

.App {
  text-align: center;
}
